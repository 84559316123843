<script lang="ts" setup>
import { breakpointsTailwind } from "@vueuse/core";

const baseURL = useRuntimeConfig().app.baseURL;
const breakpoints = useBreakpoints(breakpointsTailwind);

const darkLogo = `${baseURL}images/logo/pixellot-logo-dark.png`;
const lightLogo = `${baseURL}images/logo/pixellot-logo-light.png`;

const localePath = useLocalePath();
const largerThanMd = breakpoints.greaterOrEqual("lg");
const colorMode = useColorMode();
const route = useRoute();
const isLightLogo = computed(() => route.meta.layout === "outside" || (route.path.match(/(login|sign-up)/) && largerThanMd.value));
const logoURL = computed(() => (isLightLogo.value ? lightLogo : colorMode.value === "dark" ? lightLogo : darkLogo));
</script>

<template>
  <div class="absolute left-0 top-0 z-10 flex w-full items-center justify-between px-4 py-8 md:px-10">
    <div class="grid place-items-center overflow-hidden">
      <NuxtLink :to="localePath('/')">
        <img
          :src="logoURL"
          width="140"
          height="22"
          aria-label="Pixellot logo"
          alt="Pixellot logo"
        >
      </NuxtLink>
    </div>

    <div class="flex items-center gap-6">
      <UserMenu />
      <slot />
    </div>
  </div>
</template>
